<template>
  <v-app id="app">
    <component :is="component">
      <slot />
    </component>
    <notification />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'

import FullscreenLayout from '@/layouts/FullscreenLayout.vue'
import PatientLayout from '@/layouts/PatientLayout.vue'
import CardLayout from '@/layouts/CardLayout.vue'
import BasicLayout from '@/layouts/BasicLayout.vue'
import layouts from '@/layouts/defined.layouts'
import { simulateIpad } from '@/utils/deviceInfo'

const EVENTS = [
  'mousemove',
  'keydown',
  'mousedown',
  'touchstart',
  'touchmove',
  'scroll',
]
const TIMEOUT = 20 * 60 * 1000

export default Vue.extend({
  name: 'App',
  components: {
    FullscreenLayout,
    PatientLayout,
    CardLayout,
    BasicLayout,
    Notification: () => import('@/components/Notification.vue'),
  },
  data() {
    return {
      lastActivityTime: null,
      setTimeoutId: '',
    }
  },
  computed: {
    component() {
      if (this.$route.meta.layout === layouts.PATIENT) return 'PatientLayout'
      if (this.$route.meta.layout === layouts.FULLSCREEN)
        return 'FullscreenLayout'
      if (this.$route.meta.layout === layouts.CARD) return 'CardLayout'

      return 'BasicLayout'
    },
  },
  methods: {
    logout() {
      if (!simulateIpad) {
        this.$auth.logout(true)
      }
    },
    setLastActivity() {
      if (this.setTimeoutId) {
        clearTimeout(this.setTimeoutId)
      }

      this.lastActivityTime = Date.now()

      this.setTimeoutId = setTimeout(() => {
        this.logout()
      }, TIMEOUT)
    },
    handleVisibilityChange() {
      if (!document.hidden) {
        const now = Date.now()
        const inactivityTime = now - this.lastActivityTime

        if (inactivityTime > TIMEOUT) {
          this.logout()
        } else {
          clearTimeout(this.setTimeoutId)
          const remainingTime = TIMEOUT - inactivityTime

          this.setTimeoutId = setTimeout(() => {
            this.logout()
          }, remainingTime)
        }
      }
    },
  },
  created() {
    EVENTS.forEach(event =>
      window.addEventListener(event, this.setLastActivity, false),
    )
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false,
    )
    this.setLastActivity()
  },
  destroyed() {
    EVENTS.forEach(event =>
      window.removeEventListener(event, this.setLastActivity, false),
    )
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false,
    )
  },
})
</script>
