import { createNamespacedHelpers } from 'vuex'
import loadingState from '@/constants/loadingState'
import service from '@/services/patient-service'

export const selectedPatientModule = 'selectedPatient'

const defaultClockNotation = '24h'

const selectedPatientGetters = [
  'selectedPatientConfiguration',
  'selectedPatientClockNotation',
  'selectedPatientConfigurationLoadingState',
  'selectedPatientTreatmentArmId',
]
Object.freeze(selectedPatientGetters)

const selectedPatientActions = [
  'loadPatientConfigurationRequested',
  'clearPatientConfigurationRequested',
  'selectedPatientUpdateAppState',
  'selectedPatientUpdatePatientState',
  'selectedPatientUpdateConfiguration',
]
Object.freeze(selectedPatientActions)

const { mapGetters, mapActions } = createNamespacedHelpers(
  selectedPatientModule,
)

export const selectedPatientMapGetters = () =>
  mapGetters(selectedPatientGetters)

export const selectedPatientMapActions = () =>
  mapActions(selectedPatientActions)

let latestPatientNumberRequested: string = null

export type appStateEnum = 'completed' | 'pending' | 'blocked'
export type patientStateEnum = 'activated' | 'in_follow_up' | 'completed'

interface PatientState {
  currentState?: patientStateEnum
  activatedAt?: {
    time: string
    timeZone: string
  }
}

interface Configuration {
  appState?: appStateEnum
  patientNumber?: string
  clockNotation?: string
  treatmentArmId?: string
  patientState?: PatientState
}

interface SelectedPatientState {
  configuration: Configuration
  loadingState: string
}

export default {
  namespaced: true,
  state: {
    configuration: {},
    loadingState: loadingState.INITIAL,
  },
  getters: {
    selectedPatientConfiguration:
      (state: SelectedPatientState) => (patientNumber: string) => {
        if (patientNumber === state.configuration.patientNumber) {
          return state.configuration
        }

        return {}
      },

    selectedPatientTreatmentArmId:
      (state: SelectedPatientState) => (patientNumber: string) => {
        if (
          patientNumber === state.configuration.patientNumber &&
          state.configuration?.treatmentArmId
        ) {
          return state.configuration.treatmentArmId
        }

        return ''
      },

    selectedPatientClockNotation:
      (state: SelectedPatientState) => (patientNumber: string) => {
        if (
          patientNumber === state.configuration.patientNumber &&
          state.configuration.clockNotation
        ) {
          return state.configuration.clockNotation
        }

        return defaultClockNotation
      },

    selectedPatientConfigurationLoadingState: (state: SelectedPatientState) =>
      state.loadingState,
  },
  mutations: {
    LOAD_CONFIGURATION(state: SelectedPatientState, patientNumber: string) {
      latestPatientNumberRequested = patientNumber
      state.loadingState = loadingState.LOADING
      state.configuration = {}
    },
    UPDATE_CONFIGURATION(state: SelectedPatientState, configuration: Object) {
      state.configuration = configuration || {}
      state.loadingState = loadingState.LOAD_SUCCEEDED
    },
    UPDATE_CONFIGURATION_APP_STATE(
      state: SelectedPatientState,
      payload: { appState: appStateEnum },
    ) {
      state.configuration = {
        ...state.configuration,
        appState: payload.appState,
      }
    },
    UPDATE_CONFIGURATION_PATIENT_STATE(
      state: SelectedPatientState,
      payload: { appState: appStateEnum },
    ) {
      state.configuration = {
        ...state.configuration,
        appState: payload.appState,
      }
    },
    UPDATE_CONFIGURATION_FAILED(state: SelectedPatientState) {
      state.loadingState = loadingState.LOAD_ERRORED
    },
    CLEAR_CONFIGURATION(state: SelectedPatientState) {
      state.loadingState = loadingState.INITIAL
      state.configuration = {}
    },
  },
  actions: {
    clearPatientConfigurationRequested({ commit }) {
      commit('CLEAR_CONFIGURATION')
    },
    selectedPatientUpdateAppState({ commit }, payload) {
      if (payload.patientNumber === latestPatientNumberRequested) {
        commit('UPDATE_CONFIGURATION_APP_STATE', payload)
      }
    },
    selectedPatientUpdatePatientState({ commit }, payload) {
      if (payload.patientNumber === latestPatientNumberRequested) {
        commit('UPDATE_CONFIGURATION_PATIENT_STATE', payload)
      }
    },
    selectedPatientUpdateConfiguration({ commit }, payload) {
      if (payload.patientNumber === latestPatientNumberRequested) {
        commit('UPDATE_CONFIGURATION', payload)
      }
    },
    async loadPatientConfigurationRequested({ commit }, patientNumber: string) {
      commit('LOAD_CONFIGURATION', patientNumber)
      commit(`globalProgress/SET_LOADING`, null, { root: true })

      try {
        const response: { data?: any } =
          await service.getConfiguration(patientNumber)

        if (response.data?.patientNumber === latestPatientNumberRequested) {
          commit('UPDATE_CONFIGURATION', response.data)
        }
      } catch (error) {
        commit('UPDATE_CONFIGURATION_FAILED', error)
      } finally {
        commit('globalProgress/SET_NOT_LOADING', null, { root: true })
      }
    },
  },
}
