<template>
  <v-app-bar
    app
    clipped-left
    color="#001965"
    height="56"
    flat
    :extension-height="extensionHeight"
  >
    <BoxedDiv class="d-flex align-center justify-space-between">
      <router-link class="brand-logo" to="/">
        <EpidLogo />
      </router-link>

      <div class="link-group" v-if="isUserLoaded">
        <a v-if="trialsLink" class="link-item" :href="trialsLink"> Trials</a>
        <router-link
          v-if="showMonitorLinks"
          class="link-item"
          :to="{ name: 'trialData' }"
          >Trial data extract</router-link
        >
        <router-link
          v-if="showSiteLinks"
          class="link-item"
          :to="{ name: 'site' }"
        >
          Patients
        </router-link>
        <router-link
          v-if="showSiteLinks"
          class="link-item"
          :to="{ name: 'dataExtract' }"
        >
          Data extract
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <div class="user spacing-2">
        <strong>{{ userEmail }}</strong>
        <span>{{ rolesText }}</span>
      </div>

      <div class="pl-10 pr-6">
        <epid-btn
          v-if="isUserLoaded"
          text
          @click="logout"
          color="white"
          data-testid="logout-call-to-action"
          icon="mdi-logout"
        />
      </div>
    </BoxedDiv>
    <v-progress-linear
      v-if="showProgress"
      :active="showProgress"
      indeterminate
      absolute
      bottom
      color="#d9e6f8"
    />
    <template v-if="isTrialReadOnly" #extension>
      <BoxedDiv class="d-flex align-center justify-start">
        <IconViewOnly class="icon-view-only" />
        <span class="ml-1"> This trial is set to “View only” </span>
      </BoxedDiv>
    </template>
  </v-app-bar>
</template>

<script>
import EpidLogo from '../images/EpidLogo'
import IconViewOnly from '../icons/IconViewOnly'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import { userMapGetters } from '@/store/modules/userModule'
import { rolesText } from '@/constants/userRole'
import BoxedDiv from '@/components/layout/BoxedDiv'
import globalProgressGettersMixins from '@/components/mixins/store/globalProgressGettersMixins'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'
import EpidBtn from '@/components/EpidBtn.vue'

export default {
  name: 'AppBar',
  mixins: [globalProgressGettersMixins, userGettersMixin],
  components: { EpidBtn, BoxedDiv, EpidLogo, IconViewOnly },
  computed: {
    ...userMapGetters(),
    ...trialSettingsMapGetters(),
    isTrialReadOnly() {
      return this.trialStatus === 'view-only'
    },
    extensionHeight() {
      return this.isTrialReadOnly ? 55 : 0
    },
    showProgress() {
      return this.showLoadingProgress
    },
    showSiteLinks() {
      return this.$route.params.siteNo
    },
    showMonitorLinks() {
      return this.isUserLoaded && this.userIsMonitor && !this.showSiteLinks
    },
    trialsLink() {
      return `${this.$appSettings.identityServerAuthority}`
    },
    rolesText() {
      return rolesText(this.trialRole)
    },
  },
  methods: {
    logout() {
      this.$auth.logout(true)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep div.v-toolbar__extension {
  background-color: #deb74c;
  color: white;
}

.user {
  color: white;
  font-size: 12px;
  line-height: 18px;

  > * {
    display: block;
    min-height: 18px;
  }
}
.icon-view-only {
  margin: auto 54px auto 0;
}
.brand-logo {
  color: white;
  height: 25px;
  margin: auto 54px auto 0;
}
.link-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
}
.link-item {
  color: $nn-white;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
}
::v-deep .link-item + .link-item {
  margin-left: 16px;
}
</style>
