<template>
  <v-btn
    v-bind="{ ...$attrs }"
    v-on="$listeners"
    rounded
    :color="calcColor"
    :depressed="isDepressed"
    :elevation="caclElevation"
    :outlined="outlined"
    :small="small"
    :x-small="fab"
    :fab="fab"
    :text="text"
    :class="[{ 'ml-4': isSubsequent }, { 'mr-4': isLeading }]"
    :icon="icon && !hasSlotDefault && !fab"
    class="epid-btn"
  >
    <v-icon
      v-if="icon"
      :small="hasSlotDefault"
      :class="{ 'mr-2': hasSlotDefault }"
      >{{ icon }}</v-icon
    >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'epid-btn',
  inheritAttrs: false,
  props: {
    primary: { type: Boolean, default: false, required: false },
    secondary: { type: Boolean, default: false, required: false },
    destructive: { type: Boolean, default: false, required: false },
    small: { type: Boolean, default: false, required: false },
    fab: { type: Boolean, default: false, required: false },
    text: { type: Boolean, default: false, required: false },
    lighten: { type: Boolean, default: false, required: false },
    outlined: { type: Boolean, default: false, required: false },
    icon: { type: String, default: null, required: false },
    color: { type: String, default: null, required: false },
    isSubsequent: { type: Boolean, default: false, required: false },
    isLeading: { type: Boolean, default: false, required: false },
    elevation: { type: String, default: null, required: false },
  },
  computed: {
    caclElevation() {
      if (this.elevation) return this.elevation
      if (this.primary) return 2
      if (this.secondary || this.lighten) return 1
      return null
    },
    calcColor() {
      if (this.color) return this.color
      if (this.destructive) return 'error'
      if (this.primary) return 'secondary'
      if (this.lighten) return '#f7f6f3'

      return ''
    },
    hasSlotDefault() {
      return !!this.$slots.default
    },
    isDepressed() {
      return this.outlined || this.fab
    },
  },
}
</script>

<style lang="scss" scoped>
.epid-btn {
  text-transform: none;
}
</style>
